import { FormattedMessage } from "react-intl";

export default function Error() {
    return (
        <>
            <title>Error</title>
            <div className="min-h-screen font-sans antialiased bg-[#044000B]">
                <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
                    <main className="grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="shrink-0 flex justify-center">
                            <a href="/" className="inline-flex">
                                <span className="sr-only">
                                    <FormattedMessage id="Voice" />
                                </span>
                                <img
                                    src="/images/voice-logo.svg"
                                    alt="Voice logo"
                                />
                            </a>
                        </div>
                        <div className="py-16">
                            <div className="text-center">
                                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                    <FormattedMessage id="Whoops! Something went wrong." />
                                </h1>
                                <p className="mt-2 text-base text-gray-500">
                                    <FormattedMessage id="We're sorry, but it seems like there was an error while processing your request" />
                                </p>
                                <div className="mt-6">
                                    <a
                                        href={route("home")}
                                        className="text-base font-medium text-red-600 hover:text-red-500"
                                    >
                                        <span
                                            aria-hidden="true"
                                            className="mr-2"
                                        >
                                            &larr;
                                        </span>
                                        <FormattedMessage id="Home" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </main>
                    <footer className="shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                        <nav className="flex justify-center space-x-4">
                            <a
                                href={route("contact")}
                                className="text-sm font-medium text-gray-500 hover:text-gray-600"
                            >
                                <FormattedMessage id="Contact Support" />
                            </a>
                        </nav>
                    </footer>
                </div>
            </div>
        </>
    );
}
